import React, { useEffect, useRef, useState, useMemo } from "react";
import pLimit from 'p-limit';
import {
    GridEditInputCell,
    GridRenderEditCellParams,
    GridRenderCellParams,
    GridRowSelectionModel,
    GridCallbackDetails,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport,
    useGridApiContext,
    useGridApiRef,
    GridValueGetterParams,
    GridColDef,
    GridRowId,
    GridToolbarQuickFilter,
    GridPaginationModel,
} from "@mui/x-data-grid-pro";
import {
    Autocomplete,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Popover,
    Snackbar,
    SnackbarCloseReason,
} from "@mui/material";
import {
    saveResults,
} from "../services/results";
import { Alert, Button, TextField, MenuItem } from "@mui/material";
import { Stack } from "@mui/system";
import SaveIcon from "@mui/icons-material/Save";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ExpandIcon from '@mui/icons-material/Expand';
import { useNavigate } from "react-router";
import { GetResultStatusChip } from "./StatusChips";
import { apiFetch } from "../services/fetch";
import SampleSelect from "./SampleSelect";
import ButtonTray from "./ButtonTray";
import { DataTableContainer } from "./TableContainer";
import TableFrame from "./TableFrame";
import ProgressOverlay from "./ProgressOverlay";
import { useDataGridState } from "../hooks/useDataGridState";
import { StyledResultsDataGrid } from "./ResultsTableStyles";
import { GetFlags } from "./ResultsTableStyles";
import { RenderOrderButton } from "./ResultsTableStyles";
import {
    RefRangeList,
    Result,
    ResultUpdate,
    ResultUpdateAction,
    ResultUpdateActionUpdate,
    ResultUpdateActionValidate,
    ResultUpdateActionReject,
    ResultUpdateActionClear,
    ResultUpdateAvailableActions,
    TextEvent
} from "../constants/types";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { DateFromString, FormatDateUTC } from "../services/util";
dayjs.extend(utc);


type UpdateResultAPI = {
    [key: number]: ResultUpdate[]
}

type OverrideDetails = {
    Value: string,
    Abnormal: boolean,
    Critical: boolean
}

type EditedRow = {
    id: number, value: string,
    SampleID: number
}

const CustomToolbar = ({ handleExpand, expansionDepth, handleBarcodeFilter, handleBarcodeClose, barcodeAnchor, setBarcodeFilter }: { expansionDepth: Number, handleExpand: () => void, handleBarcodeFilter: () => void, handleBarcodeClose: () => void, barcodeAnchor: HTMLButtonElement | null, setBarcodeFilter: (barcodes: string[]) => void }) => {
    const open = Boolean(barcodeAnchor);
    const id = open ? 'simple-popover' : undefined;

    const handleBarcodeFilterText = (e: React.ChangeEvent<HTMLInputElement>) => {
        let text = e.target.value
        const barcodes = text.replace(/[\r\n]+/g, ' ').split(' ').filter(barcode => barcode.trim() !== '');

        setBarcodeFilter(barcodes)
    }

    return (
        <GridToolbarContainer>
            <Stack direction="row" justifyContent="space-between" width="100%">
                <div>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                    {expansionDepth === 0 ? <Button startIcon={<ExpandIcon />} onClick={handleExpand} size="small">Expand</Button> :
                        <Button startIcon={<ExpandIcon />} onClick={handleExpand} size="small">Collapse</Button>}
                    <Button startIcon={<PlaylistAddIcon />} onClick={handleBarcodeFilter} size="small" id={id}>Barcodes</Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={barcodeAnchor}
                        onClose={handleBarcodeClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <TextField sx={{ p: 1 }} onChange={handleBarcodeFilterText} label="Input Barcodes" />
                    </Popover>
                </div>
                <GridToolbarQuickFilter />
            </Stack>
        </GridToolbarContainer>
    );
}
const handleReplicate = async (result: Result, setSaved: any, setErrors: any) => {
    console.log(result)
    try {
        await apiFetch(`/results/${result.ID}/replicate`, 'POST')
        setSaved((saved: boolean) => !saved)
    } catch (e) {
        console.log(e)
        setErrors(["Failed to add replicate for result"])
    }
}

const deleteReplicate = async (result: Result, setSaved: any, setErrors: any) => {
    console.log(result)
    try {
        await apiFetch(`/results/${result.ID}/replicate`, 'DELETE')
        setSaved((saved: boolean) => !saved)
    } catch (e) {
        console.log(e)
        setErrors(["Failed to delete replicate"])
    }
}

const initOverride = {
    Value: "",
    Abnormal: false,
    Critical: false,
}

const GetActionButtons = ({ params, setErrors, setSaved, setOverrideDetails, setOverride }: { params: GridRenderCellParams, setErrors: any, setSaved: any, setOverrideDetails: any, setOverride: any }) => {
    if (
        !params ||
        params.row.IsPanel ||
        (params.row.Status !== "Pending" && params.row.Status !== "Updated")
    ) {
        return <></>;
    }
    return (
        <Stack direction="row" spacing={2}>
            <Button
                size="small"
                variant="contained"
                color="warning"
                sx={{ px: 1 }}
                onClick={() => {
                    setOverrideDetails(initOverride);
                    setOverride(params.row);
                }}
            >
                Override
            </Button>
            {!params.row?.ReplicateParentID ?
                <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    sx={{ px: 1 }}
                    onClick={() => {
                        handleReplicate(params.row, setErrors, setSaved);
                    }}
                >
                    Replicate
                </Button>
                :
                <Button
                    size="small"
                    variant="contained"
                    color="error"
                    sx={{ px: 1 }}
                    onClick={() => {
                        deleteReplicate(params.row, setErrors, setSaved);
                    }}
                >
                    Delete
                </Button>
            }
        </Stack>
    );
}

const RenderValueCell = ({ params, editedRows, disableEdit }: { params: GridRenderEditCellParams, editedRows: React.MutableRefObject<EditedRow[]>, disableEdit: boolean | undefined }) => {
    const apiRef = useGridApiContext();
    const { id, value, field } = params;
    const handleValueChange = (event: TextEvent) => {
        const newValue = event.target.value; // The new value entered by the user
        apiRef.current.setEditCellValue({ id, field, value: newValue });
        editedRows.current = {
            ...editedRows.current,
            [params.id]: {
                id: params.id,
                value: newValue,
                SampleID: params.row?.SampleID,
            },
        };
    };

    if (disableEdit) {
        return (
            <TextField
                disabled
                value={value}
                onChange={handleValueChange}
            />
        );
    }

    if (params.row.MethodID === null) {
        return (
            <GridEditInputCell
                {...params}
                onValueChange={(v: TextEvent) => {
                    editedRows.current = {
                        ...editedRows.current,
                        [params.id]: {
                            id: params.id,
                            value: v.target.value,
                            SampleID: params.row?.SampleID,
                        },
                    };
                }}
            />
        );
    } else if (params.row.Method?.RefRangeNumeric.length > 0 && params.row.Method?.RefRangeList.length > 0) {
        return (
            <Autocomplete
                freeSolo // This prop allows free text input
                fullWidth
                autoFocus
                value={value || ""}
                onInputChange={(_, newValue) => {
                    console.log(newValue);
                    handleValueChange({ target: { value: newValue } })
                }}
                onChange={(_, newValue) => {
                    console.log(newValue);
                    handleValueChange({ target: { value: newValue } })
                }}
                options={params.row.Method.RefRangeList.map((option: any) => option.Value)} // Define your options here
                sx={{
                    '& .MuiAutocomplete-listbox': { // Targeting the listbox directly
                        '& .MuiAutocomplete-option': {
                            fontSize: '0.5rem', // Smaller font size
                            padding: '2px 2px', // Adjust padding to make each option smaller
                        },
                    },
                }}
                renderInput={(params) => (
                    <TextField {...params}
                        variant="outlined"
                        fullWidth
                        autoFocus
                    />
                )}
            />
        )

    } else if (params.row.Method?.RefRangeNumeric.length > 0) {
        return (
            <TextField
                inputProps={{ type: "number", step: 0.01 }}
                autoFocus
                value={value}
                onChange={handleValueChange}
            />
        );
    } else if (params.row.Method?.RefRangeList.length > 0) {
        return (
            <TextField
                select
                autoFocus
                value={value}
                onChange={handleValueChange}
                sx={{ width: "100%" }}
            >
                {params?.row?.Method?.RefRangeList.map((option: RefRangeList, i: number) => (
                    <MenuItem key={i} value={option.Value}>
                        {option.Value}
                    </MenuItem>
                ))}
            </TextField>
        );
    }
    return <TextField value={value} onChange={handleValueChange} />;
};

const defaultGridState = {
    filter: {
        filterModel: {
            items: [],
            logicOperator: 'and',
            quickFilterExcludeHiddenColumns: false,
            quickFilterLogicOperator: 'and',
            quickFilterValues: []
        },
    },
    sorting: { sortModel: [] },
    pagination: { paginationModel: { page: 0, pageSize: 500 } }
}

export default function ResultsTable({ filter, disableEdit }: { filter: string, disableEdit: boolean | undefined }) {
    // stores updated result values
    const [allResults, setAllResults] = useState<Result[]>([]);
    const apiRef = useGridApiRef();
    const [expansionDepth, setExpansionDepth] = useState(0);
    const [saved, setSaved] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
    const editedRows = useRef<EditedRow[]>([]);
    const [showError, setShowError] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const [rowCount, setRowCount] = useState(0);
    const [override, setOverride] = useState<null | Result | string>(null);
    const [progress, setProgress] = useState(0);
    const [initState, setInitState] = useState<GridInitialStatePro>({});
    const [initialState, setInitialState] = useDataGridState(defaultGridState, apiRef, 'dataGridTable-results')
    const [progressTitle, setProgressTitle] = useState("");
    const [barcodeFilterAnchor, setBarcodeFilterAnchor] = useState<HTMLButtonElement | null>(null)
    const [overrideDetails, setOverrideDetails] = useState<OverrideDetails>(initOverride);
    const [changeSampleDialog, setChangeSampleDialog] = useState(false)
    const [newSamples, setNewSamples] = useState([])

    const handlePaginationModelChange = (paginationModel: GridPaginationModel) => {
        console.log(paginationModel)
        setInitialState({ ...initialState, pagination: { paginationModel: paginationModel } })
    }

    const handleSortModelChange = (sortModel: any) => {
        setInitialState({ ...initialState, sorting: { sortModel: sortModel } })
    }

    /* @ts-ignore */
    const handleFilterModelChange = (filterModel) => {
        setInitialState({ ...initialState, filter: { filterModel: filterModel } })
    }

    useEffect(() => {
        console.log('init state changing', initState);
        apiRef.current.restoreState(initState)
    }, [initState, apiRef])

    useEffect(() => {
        setSelectedRows([])
    }, [initialState])

    useEffect(() => {
        console.log('initialState', initialState)
        setInitState(initialState)
    }, [])

    useEffect(() => {
        if (!initialState.pagination.paginationModel.pageSize) {
            return
        }


        setLoading(true);
        let statusFilter = '';

        switch (filter) {
            case "validated":
                statusFilter = "validated"
                break
            case "pending":
                statusFilter = "pending,updated"
                break
            case "tnp":
                statusFilter = "rejected"
                break
            case "waiting_on_sample":
                statusFilter = "waiting on sample"
                break
            case "pending_approval":
                statusFilter = "pending,updated"
                break
        }

        const get = async () => {

            /* @ts-ignore */
            const response = await apiFetch(`/results/?status=${statusFilter}&limit=${initialState.pagination.paginationModel.pageSize}&page=${initialState.pagination.paginationModel.page}`, "POST", initialState)
            setAllResults(response.Data)
            setRowCount(response.Count)
            setLoading(false)
        }
        get()

    }, [saved, filter, initialState]);

    const handleBarcodesFilter = (barcodeFilter: string[]) => {
        if (barcodeFilter.length < 1) {
            return
        }

        const filterItems = barcodeFilter.map((barcode) => ({
            field: "Sample",
            operator: "equals",
            value: barcode
        }))

        /* @ts-ignore */
        handleFilterModelChange({ ...initialState.filter, items: filterItems, logicOperator: "or" })

        // const filtered: Result[] = allResults.filter((result) => barcodeFilter.includes(result.Sample.Barcode))
        // setAllResults(filtered)
    }

    const handleSelected = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
        const selectedSet = new Set<GridRowId>(rowSelectionModel);

        // Find newly deselected rows
        const previouslySelected = new Set(selectedRows);
        const deselectedRows = Array.from(previouslySelected).filter(id => !selectedSet.has(id));

        // Remove deselected rows and their children
        deselectedRows.forEach(deselectedRow => {
            selectedSet.delete(deselectedRow);

            allResults.forEach(res => {
                if (res.Hierarchy && res.Hierarchy.includes(Number(deselectedRow))) {
                    selectedSet.delete(res.ID);
                }
            });
        });

        // Add newly selected rows and their children
        rowSelectionModel.forEach(sel => {
            if (!previouslySelected.has(sel)) {
                selectedSet.add(sel);

                allResults
                    .filter(res => res.Hierarchy?.includes(Number(sel)))
                    .forEach(child => selectedSet.add(child.ID));
            }
        });

        setSelectedRows(Array.from(selectedSet));
    }


    const handleBarcodeFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setBarcodeFilterAnchor(event.currentTarget)
    }

    const handleBarcodeClose = (_: React.MouseEvent<HTMLButtonElement>) => {
        setBarcodeFilterAnchor(null)
    }

    const handleBulkOverride = () => {

        let updatedResults: UpdateResultAPI = {}

        const findResultRow = (id: GridRowId) => {
            return allResults.find((r) => r.ID === id);
        };

        for (let r of selectedRows) {
            let oldResult = findResultRow(r);

            if (!oldResult) {
                console.log("unable to find oldResult for ", r)
                continue
            }

            if (!updatedResults[oldResult.SampleID]) {
                updatedResults[oldResult.SampleID] = [];
            }
            updatedResults[oldResult.SampleID].push({
                ResultID: Number(r),
                Value: overrideDetails.Value,
                Abnormal: overrideDetails.Abnormal,
                Critical: overrideDetails.Critical,
                BypassMethod: true,
                Action: ResultUpdateActionUpdate,
            });
        }
        setProgressTitle("Overriding Results")
        saveResultsInBatches(updatedResults, 50)

    }

    const handleOverride = async (result: Result) => {
        if (!overrideDetails) {
            return
        }

        await saveResults(
            [
                {
                    Value: overrideDetails.Value,
                    Abnormal: overrideDetails.Abnormal,
                    Critical: overrideDetails.Critical,
                    BypassMethod: true,
                    Action: ResultUpdateActionUpdate,
                    ResultID: result.ID,
                },
            ],
            result.SampleID
        );
        setSaved(!saved);
    };

    /* @ts-ignore */
    const columns: GridColDef[] = useMemo(() => [
        {
            field: "OrderID",
            headerName: "Order",
            width: 75,
            type: "number",
            renderCell: (params: GridRenderCellParams) => <RenderOrderButton params={params} navigate={navigate} />,
            valueGetter: (row: GridValueGetterParams) => row.row?.OrderID
        },
        {
            field: "Sample",
            headerName: "Sample",
            width: 110,
            valueGetter: (row: GridValueGetterParams) => row.row?.Barcode,
        },
        {
            field: "DateReceived",
            headerName: "Date Received",
            width: 110,
            type: "date",
            valueGetter: (row: GridValueGetterParams) => DateFromString(row.row?.DateReceived),
            valueFormatter: ({ value }) => { return value && FormatDateUTC(value) },
        },
        {
            field: "DateCollected",
            headerName: "Date Collected",
            width: 110,
            type: "date",
            valueGetter: (row: GridValueGetterParams) => DateFromString(row.row?.DateCollected),
            valueFormatter: ({ value }) => { return value && FormatDateUTC(value) },
        },
        {
            field: "ValidatedAt",
            headerName: "Validated At",
            width: 110,
            type: "date",
            valueGetter: (row: GridValueGetterParams) => DateFromString(row.row?.ValidatedAt),
            valueFormatter: ({ value }) => { return value && FormatDateUTC(value) },
        },
        {
            field: "Patient",
            headerName: "Patient",
            width: 120,
            valueGetter: (row: GridValueGetterParams) =>
                row.row?.PatientLastName +
                ", " +
                row.row?.PatientFirstName,
        },
        {
            field: "Specimen",
            headerName: "Specimen",
            width: 100,
        },
        {
            field: "TestName",
            headerName: "Test",
            width: 150,
        },
        {
            field: "Value",
            headerName: "Value",
            width: 120,
            renderEditCell: (params: GridRenderEditCellParams) => <RenderValueCell params={params} editedRows={editedRows} disableEdit={disableEdit} />,
            editable: (row: GridValueGetterParams) => !row.row?.IsPanel && !disableEdit,
        },
        { field: "FinalValue", headerName: "Report Value", width: 120 },
        {
            field: "Flags",
            headerName: "Flags",
            width: 100,
            align: "center",
            renderCell: (params: GridRenderCellParams) => (
                <GetFlags value={params.row} />
            ),
        },
        {
            field: "Status",
            headerName: "Status",
            width: 120,
            renderCell: (params: GridRenderCellParams) => (
                <GetResultStatusChip status={params.row?.Status} />
            ),
        },
        {
            field: "Action",
            headerName: "Action",
            width: 200,
            renderCell: (params: GridRenderCellParams) => <GetActionButtons params={params} setSaved={setSaved} setErrors={setErrors} setOverrideDetails={setOverrideDetails} setOverride={setOverride} />,
        },
        {
            field: "Facility",
            headerName: "Facility",
            width: 110,
            valueGetter: (row) => row.row?.FacilityName,
        },
        {
            field: "Department",
            headerName: "Department",
            width: 110,
            valueGetter: (row) => row.row?.DepartmentName,
        },
        {
            field: "Laboratory",
            headerName: "Laboratory",
            width: 110,
            valueGetter: (row) => row.row?.LaboratoryName,
        },
        { field: "PanelID", headerName: "Parent", type: 'number', width: 80 },
    ], [disableEdit, navigate]);

    const handleSampleChange = () => {
        let updatedResults = [];

        const orderIds = new Set();

        for (let r of selectedRows) {
            let oldResult = findResultRow(Number(r));
            if (!oldResult) {
                continue
            }
            orderIds.add(oldResult.OrderID)
            updatedResults.push({
                ResultID: r,
                OrderID: oldResult.OrderID,
            });
        }

        if (orderIds.size > 1) {
            setErrors(["Multiple orders selected, unable to update sample."])
            setShowError(true)
            return
        }

        const getAvailableSamplesByOrder = async () => {
            const response = await apiFetch(`/orders/${orderIds.entries().next().value[0]}/samples`, "GET")
            setChangeSampleDialog(true)
            setNewSamples(response)
        }

        getAvailableSamplesByOrder()

    }

    const updateResultsToNewSample = (newSample: number[], results: number[]) => {

        const data = {
            ResultIDs: results,
            SampleID: newSample[0],
        }

        const updateResults = async () => {
            setLoading(true)
            try {
                /* @ts-ignore */
                await apiFetch('/results/update', "POST", data)
            } catch (e) {
                setErrors(["Failed to update sample on results."])
                setShowError(true)
            } finally {
                setLoading(false)
                setSaved(!saved)
            }
        }

        updateResults()

    }

    const findResultRow = (id: number) => {
        return allResults.find((r) => r.ID === id);
    };

    const findNewValue = (id: number): string | undefined => {
        const editedRow = editedRows.current[id]
        return editedRow?.value
    }

    const handleResultAction = (action: string) => {
        let updatedResults: UpdateResultAPI = {};
        let ResultAction: ResultUpdateAction | null = null;
        let ActionTitle: string = "";
        let saveResults: (arg0: UpdateResultAPI) => void = () => { };

        switch (action) {
            case "save":
                ResultAction = ResultUpdateActionUpdate;
                ActionTitle = "Updating Results"
                saveResults = (results) => saveResultsInBatches(results, 50)
                break;
            case "validate":
                ResultAction = ResultUpdateActionValidate;
                ActionTitle = "Validating Results"
                saveResults = (results) => saveResultsWithLimit(results, 25)
                break;
            case "tnp":
                ResultAction = ResultUpdateActionReject;
                ActionTitle = "Rejecting Results"
                saveResults = (results) => saveResultsWithLimit(results, 25)
                break;
            case "clear":
                ResultAction = ResultUpdateActionClear;
                ActionTitle = "Clearing Results"
                saveResults = (results) => saveResultsWithLimit(results, 25)
                break;
            default:
                console.log("unknown action: ", action)
                return
        }

        if (!ResultAction || !ResultUpdateAvailableActions.includes(ResultAction)) {
            console.log('invalid result action received', ResultAction)
            return
        }

        for (let r of selectedRows) {
            let oldResult = findResultRow(Number(r));
            if (!oldResult || oldResult?.IsPanel) {
                continue
            }
            if (!updatedResults[oldResult.SampleID]) {
                updatedResults[oldResult.SampleID] = [];
            }

            const newValue = findNewValue(Number(r))

            updatedResults[oldResult.SampleID].push({
                ResultID: Number(r),
                Action: ResultAction,
                Value: newValue
            });
        }

        setProgressTitle(ActionTitle)
        saveResults(updatedResults)
    }


    // Function to save results in batches
    const saveResultsWithLimit = async (results: UpdateResultAPI, max_concurrent_requests: number) => {
        console.log("saving results with limit", max_concurrent_requests)
        const limit = pLimit(max_concurrent_requests)
        const keys = Object.keys(results);
        let completed = 0;

        function updateProgress(increment: number) {
            completed += increment;
            const progress = (completed / keys.length) * 100;
            setProgress(progress); // Call some function to update the progress overlay
        }

        let failedPromises = 0

        const limitedPromises = keys.map(key => {
            /* @ts-ignore */
            return limit(() => saveResults(results[key], key).then((response) => {
                if (!response.ok) {
                    failedPromises += 1
                }
                updateProgress(1)
            }));
        });

        setProgress(0.1)
        try {
            await Promise.allSettled(limitedPromises);

            if (failedPromises > 0) {
                throw new Error(`Failed to save ${failedPromises} results`);
            }
        } catch (error: any) {
            setErrors([error.message]);
            setShowError(true);
        } setProgress(0)
        setSaved(!saved)
    }
    // Function to save results in batches
    const saveResultsInBatches = async (results: UpdateResultAPI, batchSize: number) => {
        const keys = Object.keys(results);
        let completed = 0;

        // A function to update progress, assuming there's a function like setProgress that updates the UI
        function updateProgress(increment: number) {
            completed += increment;
            const progress = (completed / keys.length) * 100;
            setProgress(progress); // Call some function to update the progress overlay
        }

        setProgress(0.1)
        for (let i = 0; i < keys.length; i += batchSize) {
            /* @ts-ignore */
            const batch = keys.slice(i, i + batchSize).map(key => saveResults(results[key], key));
            // Wait for the entire batch to complete before proceeding
            try {
                const responses = await Promise.all(batch);
                updateProgress(batch.length); // Update progress after each batch

                const allOk = responses.every(response => response.ok);
                if (!allOk) {
                    throw new Error("Failed to save one or more results");
                }

                // If they all succeeded, then you might update state or do other things
            } catch (error: any) {
                setErrors([error.message]);
                setShowError(true);
                break; // Stop processing if a batch fails
            }
            setSaved(!saved)
        }
        setProgress(0)
        setSaved(!saved)
    }

    const handleClose = (_: React.SyntheticEvent | React.MouseEvent, reason: SnackbarCloseReason): void => {
        if (reason !== "clickaway") {
            setShowError(false);
        }
    };

    const handleExpand = () => {
        setExpansionDepth((depth) => {
            if (depth === 0) {
                return 10
            } else {
                return 0
            }
        })
    }



    if (!initState) {
        return <CircularProgress />
    }

    return (
        <>
            <Dialog open={override !== null}>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                label="Result"
                                name="Value"
                                size="small"
                                required
                                onChange={(e) =>
                                    setOverrideDetails({
                                        ...overrideDetails,
                                        Value: e.target.value,
                                    })
                                }
                            />
                            <FormControlLabel
                                label="Abnormal"
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            setOverrideDetails({
                                                ...overrideDetails,
                                                Abnormal: e.target.checked,
                                            })
                                        }
                                    />
                                }
                            />
                            <FormControlLabel
                                label="Critical"
                                control={
                                    <Checkbox
                                        onChange={(e) =>
                                            setOverrideDetails({
                                                ...overrideDetails,
                                                Critical: e.target.checked,
                                            })
                                        }
                                    />
                                }
                            />
                        </Stack>
                        <Alert severity="warning">
                            This result will bypass method processing.
                        </Alert>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setOverride(null);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        startIcon={<SaveIcon />}
                        color="success"
                        variant="contained"
                        onClick={() => {
                            if (override === 'bulk') {
                                handleBulkOverride()
                            } else if (!!override && typeof override !== "string") {
                                handleOverride(override);
                            }
                            setOverride(null);
                        }}
                    >
                        <span>Save</span>
                    </Button>
                </DialogActions>
            </Dialog>
            <ProgressOverlay progress={progress} title={progressTitle} />
            <SampleSelect
                samples={newSamples}
                open={changeSampleDialog}
                setOpen={setChangeSampleDialog}
                update={updateResultsToNewSample}
                results={selectedRows} />
            <TableFrame>
                <DataTableContainer>
                    <StyledResultsDataGrid
                        getRowId={(row) => row.ID}
                        editMode="cell"
                        treeData
                        getTreeDataPath={(row) => row.Hierarchy}
                        groupingColDef={{ headerName: "ID", width: 95, valueGetter: (params) => params.id, sortable: false, filterable: false }}
                        defaultGroupingExpansionDepth={expansionDepth}
                        disableRowSelectionOnClick
                        apiRef={apiRef}
                        loading={loading}
                        rowCount={rowCount}
                        pagination
                        paginationMode="server"
                        paginationModel={initialState.pagination.paginationModel}
                        onPaginationModelChange={handlePaginationModelChange}
                        sortingMode="server"
                        sortModel={initialState.sorting.sortModel}
                        onSortModelChange={handleSortModelChange}
                        filterMode="server"
                        filterModel={initialState.filter.filterModel}
                        onFilterModelChange={handleFilterModelChange}
                        pageSizeOptions={[25, 50, 100, 500, 1000, 3000]}
                        initialState={{
                            ...initState
                        }}
                        isCellEditable={(params) => !params.row.IsPanel}
                        // isRowSelectable={(params) => !params.row.IsPanel}
                        getRowClassName={(params) => {
                            if (params.row.Critical) {
                                return "critical";
                            } else if (params.row.Abnormal) {
                                return "abnormal";
                            } else if (params.row.IsPanel) {
                                return "panel";
                            }
                            return "";
                        }}
                        density="compact"
                        components={{ Toolbar: CustomToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                handleExpand: handleExpand,
                                expansionDepth: expansionDepth,
                                handleBarcodeFilter: handleBarcodeFilter,
                                handleBarcodeClose: handleBarcodeClose,
                                barcodeAnchor: barcodeFilterAnchor,
                                setBarcodeFilter: handleBarcodesFilter,
                            },
                        }}
                        rowSelectionModel={selectedRows}
                        onRowSelectionModelChange={handleSelected}
                        checkboxSelection={true}
                        rows={allResults}
                        columns={columns}
                    />
                </DataTableContainer>

                <ButtonTray>
                    <Button
                        variant="contained"
                        onClick={() => handleResultAction("save")}
                        disabled={disableEdit || selectedRows.length <= 0 || loading}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        disabled={disableEdit || selectedRows.length <= 0 || loading}
                        color="success"
                        onClick={() => handleResultAction("validate")}
                    >
                        Validate
                    </Button>
                    <Button
                        variant="contained"
                        disabled={disableEdit || selectedRows.length <= 0 || loading}
                        color="error"
                        onClick={() => handleResultAction("clear")}
                    >
                        Clear
                    </Button>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                            setOverrideDetails(initOverride)
                            setOverride('bulk')
                        }}
                        disabled={disableEdit || selectedRows.length <= 0 || loading}
                    >
                        Bulk Override
                    </Button>
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={handleSampleChange}
                        disabled={disableEdit || selectedRows.length <= 0 || loading}
                    >
                        Change Sample
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleResultAction("tnp")}
                        disabled={disableEdit || selectedRows.length <= 0 || loading}
                    >
                        TNP
                    </Button>
                </ButtonTray>
            </TableFrame>
            {errors.map((error, index) => (
                /* @ts-ignore */
                <Snackbar key={index} open={showError} onClose={handleClose}>
                    <Alert
                        severity="error"
                        /* @ts-ignore */
                        onClose={handleClose}
                        width="75%"
                        sx={{ m: 2 }}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        {error}
                    </Alert>
                </Snackbar>
            ))}
        </>
    );
}
