import {
    Alert,
    Autocomplete,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { getPatient, addPatient, updatePatient } from "../services/patients";
import { getFacilities } from "../services/facilities";
import { useNavigate } from "react-router";
import { getInsurances } from "../services/insurances";
import CheckIcon from "@mui/icons-material/Check";
import { MedicationsEdit } from "./MedicationsEdit";
import MedicationsTable from "./MedicationsTable";
import PatientFields from "./PatientFields";
import InsuranceInput from "./InsuranceInput";
import { apiFetch } from "../services/fetch";
import ErrorAlert from "./ErrorAlert";
import { AllergysEdit } from "./AllergyEdit";
import AllergysTable from "./AllergyTable";
import utc from 'dayjs/plugin/utc';
import { InsuranceRelationships } from "../constants/constants";
import { CustomCheckboxField } from "./CustomTextField";

dayjs.extend(utc);

export default function PatientInput({ id }) {
    const insuranceDefaultValues = {
        Insurance: {},
        InsuranceID: null,
        DateEffective: dayjs.utc("2023-01-01T00:00:00"),
        Relationship: "Self",
        DateDiscontinue: null,
        MemberNumber: "",
        GroupNumber: "",
        Subscriber: {
            FirstName: "",
            LastName: "",
            DOB: null,
        },
        Active: true,
        Primary: true,
        Secondary: false,
    };

    const initValues = {
        Enabled: true,
        FirstName: "",
        LastName: "",
        DOB: null,
        Sex: "",
        Address: {
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            ZipCode: "",
        },
        Facility: {},
        Medications: [],
        Allergies: [],
        FacilityID: "",
        SSN: "",
        PatientInsurances: [insuranceDefaultValues],
        FinancialClass: "",
        PaymentMethod: "2",
        Ethnicity: "",
        TextResults: false,
        FaxResults: false,
        EmailResults: false,
        Phone: "",
        Fax: "",
        Email: "",
    };

    const [initialValues, setInitialValues] = useState(initValues);
    const [allFacilities, setAllFacilities] = useState([]);
    const [insurances, setInsurances] = useState([]);
    const [error, setError] = useState(null);
    const [zipCode, setZipCode] = useState("");
    const [duplicateError, setDuplicateError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        getFacilities().then((p) => {
            setAllFacilities(p);
        });

        getInsurances()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to load insurances");
                }
                return p.json();
            })
            .then((p) => setInsurances(p));
    }, []);

    useEffect(() => {
        if (id) {
            getPatient(id)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to loaded patient.");
                    }
                    return p.json();
                })
                .then((p) => {
                    console.log('received dob', p.DOB)
                    p.DOB = dayjs.utc(p.DOB)
                    console.log('updating dob to', p.DOB)
                    for (let pi of p.PatientInsurances) {
                        pi.DateEffective = dayjs.utc(pi.DateEffective);
                        if (pi.DateDiscontinue) {
                            pi.DateDiscontinue = dayjs.utc(pi.DateDiscontinue);
                        }
                        if (pi.Subscriber?.DOB) {
                            pi.Subscriber.DOB = dayjs.utc(pi.Subscriber.DOB)
                        }
                    }
                    switch (p.FinancialClass) {
                        case 0:
                            p.PaymentMethod = "0";
                            break;
                        case 1:
                            p.PaymentMethod = "1";
                            break;
                        default:
                            p.PaymentMethod = "2";
                    }

                    setInitialValues(p);
                })
                .catch((e) => setError(e.message));
        }
    }, [id]);

    const getFinancialClass = (paymentMethod, patientInsurances) => {
        if (paymentMethod === "0" || paymentMethod === "1") {
            return parseInt(paymentMethod);
        }
        if (patientInsurances[0].Insurance !== undefined) {
            return patientInsurances[0].Insurance.FinancialClass;
        }
        return 0;
    };

    const handleSubmit = (values) => {
        console.log(values);
        //financial_class_xref
        // create json
        // send to api
        const finClass = getFinancialClass(
            values.PaymentMethod,
            values.PatientInsurances
        );
        values.FinancialClass = finClass;

        if (!id) {
            addPatient(values)
                .then((response) => {
                    if (response.status === 409) {
                        throw new Error("Duplicate patient");
                    } else if (!response.ok) {
                        throw new Error("Failed to create patient");
                    }
                    return response.json();
                })
                .then((rdata) => {
                    navigate("/patients/" + rdata.ID);
                })
                .catch((e) => setError(e.message));
        } else {
            updatePatient(id, values)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Failed to update patient");
                    }
                    return response.json();
                })
                .then((rdata) => {
                    navigate("/patients/" + rdata.ID);
                })
                .catch((e) => setError(e.message));
        }
    };

    const validationSchema = Yup.object().shape({
        Enabled: Yup.bool(),
        FirstName: Yup.string().required("First Name is required"),
        LastName: Yup.string().required("Last Name is required"),
        Ethnicity: Yup.string()
            .oneOf(["Hispanic or Latino", "Not Hispanic or Latino", "Unknown"], "Invalid ethnicity")
            .required("Ethnicity is required"),
        DOB: Yup.date().required("DOB is required"),
        Fax: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Fax number is not valid"
        ),
        Phone: Yup.string().matches(
            /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
            "Phone number is not valid"
        ),
        Email: Yup.string().email("Invalid email"),
        TextResults: Yup.bool(),
        EmailResults: Yup.bool(),
        FaxResults: Yup.bool(),
        Sex: Yup.string()
            .oneOf(["Male", "Female"], "Invalid sex")
            .required("Sex is required"),
        Address: Yup.object({
            Address1: Yup.string().required("Address 1 is required"),
            Address2: Yup.string(),
            City: Yup.string().required("City is required"),
            State: Yup.string().required("State is required"),
            ZipCode: Yup.string().required("ZipCode is required"),
        }),
        FacilityID: Yup.number().required("Facility is required"),
        Medications: Yup.array()
            .of(
                Yup.object().shape({
                    RxNormID: Yup.number().required(),
                    Name: Yup.string().required(),
                    DrugClasses: Yup.array()
                        .of(
                            Yup.object().shape({
                                Name: Yup.string(),
                                RxNormID: Yup.string(),
                            })
                        )
                        .required(),
                })
            )
            .notRequired(),
        SSN: Yup.string().matches(
            /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/,
            "SSN is not valid"
        ),
        PatientInsurances: Yup.array().of(
            Yup.object({
                InsuranceID: Yup.number().required("Insurance is required"),
                MemberNumber: Yup.string().required(
                    "Member number is required"
                ),
                GroupNumber: Yup.string(),
                DateEffective: Yup.date().required(
                    "Date effective is required"
                ),
                Relationship: Yup.string()
                    .oneOf(InsuranceRelationships)
                    .required("Relationship is required"),
                DateDiscontinue: Yup.date().nullable(),
                Primary: Yup.boolean().required("Type is required"),
            })
        ),
    });

    const addPatientInsurance = (setFieldValue, values) => {
        setFieldValue("PatientInsurances", [
            ...values.PatientInsurances,
            insuranceDefaultValues,
        ]);
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true,
    });

    useEffect(() => {
        // dont dup check for edits
        if (id || !formik.values.FirstName || !formik.values.LastName || !formik.values.DOB || !formik.values.FacilityID) {
            return
        }

        const search = async () => {
            try {
                const formattedDOB = dayjs(formik.values.DOB).format('YYYY-MM-DD');
                // const dob = new Date(dayjs.utc(formik.values.DOB))
                // MyDateString = MyDate.getFullYear() + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-' + ('0' + MyDate.getDate()).slice(-2);
                console.log(formattedDOB)


                const firstName = formik.values.FirstName.toLowerCase()
                const lastName = formik.values.LastName.toLowerCase()

                if (formattedDOB === "Invalid Date") {
                    return
                }

                const response = await apiFetch(
                    `/patients/search?facility_id=${formik.values.FacilityID}&first_name=${firstName}&last_name=${lastName}&dob=${formattedDOB}`,
                    "GET")
                if (response.length < 1) {
                    setDuplicateError((_) => null)
                    return
                }

                for (let p of response) {
                    if (p.FirstName.toLowerCase() === firstName && p.LastName.toLowerCase() === lastName && p.DOB.slice(0, 10) === formattedDOB) {
                        setDuplicateError(<>Patient already exists. <a href={`/patients/${p.ID}`}>Click here to view profile.</a></>)
                        return
                    }

                }
                setDuplicateError((_) => null)
            } catch (e) {
                console.log(e)
                setDuplicateError((_) => null)


            }
        }

        search()

    }, [formik.values.DOB, formik.values.LastName, formik.values.FirstName, formik.values.FacilityID])


    return (
        <Box>
            <form onSubmit={formik.handleSubmit}>
                <hr style={{ margin: "15px" }} />
                <Stack direction="column" spacing={2}>
                    <InputLabel>General</InputLabel>
                    <Stack
                        direction={{ sm: "column", md: "row" }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                    >
                        <Autocomplete
                            disablePortal
                            required
                            fullWidth
                            sx={{ display: "inline" }}
                            options={allFacilities}
                            autoFocus={true}
                            getOptionLabel={(row) => row.Name || ""}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                            }
                            value={formik.values.Facility}
                            onChange={(_, value) => {
                                formik.setFieldValue(
                                    "FacilityID",
                                    value?.ID || ""
                                );
                                formik.setFieldValue("Facility", value);
                            }}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        required
                                        {...params}
                                        error={
                                            formik.touched.FacilityID &&
                                            Boolean(formik.errors.FacilityID)
                                        }
                                        helperText={
                                            formik.touched.FacilityID &&
                                            formik.errors.FacilityID
                                        }
                                        size="small"
                                        label="Facility"
                                    />
                                );
                            }}
                        />
                        <FormControlLabel
                            sx={{ marginLeft: 0 }}
                            control={
                                <Checkbox
                                    name="Enabled"
                                    checked={formik.values?.Enabled}
                                    onChange={formik.handleChange}
                                />
                            }
                            name="Enabled"
                            label="Enabled"
                        ></FormControlLabel>
                    </Stack>
                    <InputLabel>Patient</InputLabel>
                    <ErrorAlert error={duplicateError} />
                    <PatientFields formik={formik} />
                    <InputLabel>Medications</InputLabel>
                    <MedicationsEdit
                        medications={formik.values?.Medications}
                        setMedications={(newMeds) =>
                            formik.setFieldValue("Medications", newMeds)
                        }
                    />
                    <MedicationsTable
                        medications={formik.values?.Medications}
                    />
                    <InputLabel>Allergies</InputLabel>
                    <AllergysEdit
                        allergys={formik.values?.Allergies}
                        setAllergys={(newMeds) =>
                            formik.setFieldValue("Allergies", newMeds)
                        }
                    />
                    <AllergysTable allergys={formik.values?.Allergies} />
                    <InputLabel>Default Payment Method</InputLabel>
                    <RadioGroup
                        row
                        name="PaymentMethod"
                        sx={{ display: "block" }}
                        value={formik.values.PaymentMethod}
                        onChange={(e, value) => {
                            if (
                                value === "2" &&
                                formik.values.PatientInsurances.length === 0
                            ) {
                                addPatientInsurance(
                                    formik.setFieldValue,
                                    formik.values
                                );
                            }
                            formik.handleChange(e, value);
                        }}
                        error={
                            formik.touched.PaymentMethod &&
                            Boolean(formik.errors.PaymentMethod)
                        }
                    >
                        <FormControlLabel
                            value={"2"}
                            control={<Radio />}
                            label="Insurance"
                        />
                        <FormControlLabel
                            value={"0"}
                            control={<Radio />}
                            label="Client Bill"
                        />
                        <FormControlLabel
                            value={"1"}
                            control={<Radio />}
                            label="Self Pay"
                        />
                    </RadioGroup>
                    <InsuranceInput formik={formik} insurances={insurances} />
                    <Stack direction="row" spacing={2}>
                        <Button
                            onClick={() =>
                                addPatientInsurance(
                                    formik.setFieldValue,
                                    formik.values
                                )
                            }
                            variant="contained"
                            color="secondary"
                            sx={{ color: "white" }}
                        >
                            Add Insurance
                        </Button>
                    </Stack>
                    <Divider variant="middle" />
                    <Stack direction="row" spacing={2}>
                        {id ? (
                            <Button
                                color="primary"
                                type="submit"
                                size="large"
                                startIcon={<CheckIcon />}
                                variant="contained"
                            >
                                Update Patient
                            </Button>
                        ) : (
                            <Button
                                color="primary"
                                type="submit"
                                size="large"
                                startIcon={<CheckIcon />}
                                variant="contained"
                                disabled={!!duplicateError}
                            >
                                Create Patient
                            </Button>
                        )}
                    </Stack>
                    {error !== null ? (
                        <Alert severity="error">{error}</Alert>
                    ) : null}
                </Stack>
            </form>
        </Box>
    );
}
