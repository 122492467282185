import Title from "./Title";
import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { GridToolbar } from "@mui/x-data-grid-pro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockResetIcon from "@mui/icons-material/LockReset";
import { Alert, Button, IconButton } from "@mui/material";
import { resetUser } from "../services/users";
import { CopyAll } from "@mui/icons-material";

export default function UsersTable({
    getUsers,
    setAlert,
    setError,
    setSelectedUser,
    selectedUser,
    title,
    refresh,
}) {
    const [users, setUsers] = useState([]);
    const [localRefresh, setLocalRefresh] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getUsers().then((p) => {
            setUsers(p);
            setLoading(false);
        });
    }, [refresh, getUsers, localRefresh]);

    const getCheckbox = (value) => {
        if (value.value) {
            return <CheckCircleIcon color="success" />;
        }
        return <CheckCircleIcon color="disabled" />;
    };

    const resetUserPassword = (userID) => {
        resetUser(userID)
            .then((p) => {
                if (!p.ok) {
                    throw new Error("Failed to reset users password.");
                }
                setAlert("Password reset sent.");
                setLocalRefresh(!localRefresh);
                setError(undefined);
            })
            .catch((e) => setError(e.message));
    };

    const getReset = (row) => {
        return (
            <>
                <Button
                    size="small"
                    variant="contained"
                    startIcon={<LockResetIcon />}
                    onClick={() => resetUserPassword(row.id)}
                >
                    Reset Password
                </Button>
                {row.row.PendingInvite !== "" && (
                    <IconButton
                        size="small"
                        onClick={() => {
                            navigator.clipboard.writeText(`${window.location.origin}/activate?invite=${row.row.PendingInvite}`);
                        }}
                    >
                        <CopyAll />
                    </IconButton>
                )}
            </>
        );
    };

    const columns = [
        { field: "ID", headerName: "ID", width: 45 },
        { field: "FirstName", headerName: "First Name", width: 120 },
        { field: "LastName", headerName: "Last Name", width: 120 },
        { field: "Email", headerName: "Email", width: 200 },
        { field: "Type", headerName: "Type", width: 120 },
        {
            field: "IsActive",
            headerName: "Activated",
            width: 110,
            renderCell: getCheckbox,
        },
        {
            field: "Facilities",
            headerName: "Facilities",
            width: 110,
        },
        {
            field: "Roles",
            headerName: "Roles",
            width: 110,
        },
        {
            field: "Reset",
            headerName: "Reset",
            width: 200,
            renderCell: getReset,
        },
    ];

    return (
        <React.Fragment>
            <Title>{title}</Title>
            <DataGridPro
                hideFooterRowCount
                getRowId={(row) => row.ID}
                loading={loading}
                density="compact"
                initialState={{
                    sorting: {
                        sortModel: [{ field: "ID", sort: "desc" }],
                    },
                }}
                sx={{
                    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        display: "none",
                    },
                }}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                rowSelectionModel={selectedUser}
                onRowSelectionModelChange={setSelectedUser}
                rows={users}
                columns={columns}
            />
        </React.Fragment>
    );
}
