import React, { useContext } from "react";
import Icon from "../../images/icon.png";
import { Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListItems } from "./listItems";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { SessionContext } from "../../hooks/getContext";
import AppBarUser from "../AppBarUser";
import AddSpeedDial from "../AddSpeedDial";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router";

const drawerWidth = 240;
const Drawer = styled(MuiDrawer, {
    // shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function AppBar(props) {
    const theme = useTheme();
    const session = useContext(SessionContext);
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const [open, setOpen] = React.useState(!isMobile);
    const navigate = useNavigate();
    const host = window.location.host;

    const drawerVariant = isMobile ? "temporary" : "permanent";

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <>
            <StyledAppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: "24px", // keep right padding when drawer closed
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: "36px",
                            ...(open && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {props.name}
                    </Typography>
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={2}
                        alignItems="center"
                    >
                        {!isMobile && <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                        // sx={{ flexGrow: 1 }}
                        >
                            {(host.startsWith("test") || host.startsWith("dev")) && "DEVELOPMENT"}
                        </Typography>}
                        {!isMobile ? <Button
                            color="secondary"
                            variant="contained"
                            sx={{ color: "white", minWidth: "125px", maxHeight: "40px" }}
                            onClick={() => navigate("/newOrder", { orderID: null, reset: true })}
                        >
                            New Order
                        </Button> : null}
                        <AppBarUser context={session} />
                    </Stack>
                </Toolbar>
            </StyledAppBar>
            <Drawer variant={drawerVariant} open={open}>
                <Toolbar
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        px: [1],
                    }}
                >
                    <img alt="" width="50px" src={Icon} />
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <MainListItems context={session} />
                </List>
            </Drawer>
            {props?.HideSpeedDial ? null : <AddSpeedDial />}
        </>
    );
}
