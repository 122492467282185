type IntegrationID = {
	ID: number;
	Name: string;
}

export const Integrations: IntegrationID[] = [
	{ ID: 4, Name: "Labsoft" },
	{ ID: 5, Name: "Athena" },
	{ ID: 6, Name: "VMed" },
]

export type ReportService = {
	ID: number;
	Name: string;
}

export const ReportServices: ReportService[] = [
	{ Name: "ArkStone", ID: 2 },
	{ Name: "Coriell", ID: 3 }
]

export const ReportServiceMap: { [key: number]: string } = {
	2: "ArkStone",
	3: "Coriell"
}

export const InsuranceRelationships: string[] = [
	"Self",
	"Spouse",
	"Child",
	"Mother",
	"Father",
	"Stepparent",
	"Stepchild",
]

export type OrderStatus = string

export const OrderStatusSubmitted: OrderStatus = "Submitted"
export const OrderStatusSamplesCollected: OrderStatus = "Sample(s) Collected"
export const OrderStatusSamplesReceived: OrderStatus = "Sample(s) Received"
export const OrderStatusCompleted: OrderStatus = "Completed"
export const OrderStatusCorrecting: OrderStatus = "Correcting"
export const OrderStatusCorrected: OrderStatus = "Corrected"
export const OrderStatusRejected: OrderStatus = "Rejected"

export const OrderFinalStatuses: OrderStatus[] = [OrderStatusCompleted, OrderStatusCorrected, OrderStatusRejected]
export const OrderStatuses: OrderStatus[] = [
	OrderStatusSubmitted,
	OrderStatusSamplesCollected,
	OrderStatusSamplesReceived,
	OrderStatusCompleted,
	OrderStatusCorrecting,
	OrderStatusCorrected,
	OrderStatusRejected
]

export type SampleStatus = string
export const SampleStatusCollected = "Collected"
export const SampleStatusReceived = "Received"
export const SampleStatusTransferred = "Transferred"
export const SampleStatusRejected = "Rejected"

export const SampleStatuses: SampleStatus[] = [
	SampleStatusCollected,
	SampleStatusReceived,
	SampleStatusTransferred,
	SampleStatusRejected,
]

export type Verifier = string

export const VerifierIndex: { [key: number]: string } = {
	0: "Frequency Check",
	1: "Diagnosis Validity",
}

export type FrequencyVerifierAlgorithm = string

export const FrequencyVerifierAlgorithmIndex: { [key: number]: string } = {
	0: "Verify via Date of Service",
	1: "Verify via Order Date"
}

export type ReportOrderPreference = string

export const ReportOrderPreferenceIndex: { [key: number]: string } = {
	0: "Internal Report First",
	1: "Reference/Supplemental Report First"
}
