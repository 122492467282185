import { Alert, Autocomplete, Divider, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import TestsTable from "../tables/TestsTable";
import Button from "@mui/material/Button";
import { Stack, Box } from "@mui/system";
import { Formik } from "formik";
import {
    addMethod,
    getInstruments,
    getMethods,
    updateMethod,
} from "../services/methods";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import { getTests } from "../services/tests";
import { Form } from "formik";
import * as Yup from "yup";

const ListRangeInit = {
    AgeFrom: 0,
    AgeTo: 150,
    Sex: "A",
    Value: "",
    Abnormal: false,
    Critical: false,
    CombineValueDisplay: false,
    Comment: "",
};

const NumericRangeInit = {
    AgeFrom: 0,
    AgeTo: 150,
    Sex: "A",
    ValueFrom: null,
    ValueTo: null,
    Abnormal: false,
    Critical: false,
    CombineValueDisplay: false,
    Cutoff: false,
    Comment: "",
};

function NumericRange({
    values,
    index,
    setFieldValue,
    handleChange,
    touched,
    errors,
}) {
    let rangeName = "RefRangeNumeric";
    return (
        <>
            <Stack
                direction={{ lg: "column", xl: "row" }}
                spacing={{ md: 2, lg: 0 }}
                alignItems={{ md: "stretch", lg: "center" }}
            >
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    alignItems={{ md: "stretch", lg: "center" }}
                >

                    <AgeField
                        values={values}
                        rangeName={rangeName}
                        name="AgeFrom"
                        index={index}
                        handleChange={handleChange}
                        label="Age From"
                    />
                    <AgeField
                        values={values}
                        rangeName={rangeName}
                        name="AgeTo"
                        index={index}
                        handleChange={handleChange}
                        label="Age To"
                    />
                    <SexField
                        values={values}
                        rangeName={rangeName}
                        name="Sex"
                        index={index}
                        handleChange={handleChange}
                    />
                    <CustomNumberField
                        values={values}
                        rangeName={rangeName}
                        name="ValueFrom"
                        errors={errors}
                        touched={touched}
                        label="Value From"
                        index={index}
                        handleChange={handleChange}
                        sx={{ minWidth: "130px" }}
                    />
                    <CustomNumberField
                        values={values}
                        errors={errors}
                        touched={touched}
                        rangeName={rangeName}
                        name="ValueTo"
                        label="Value To"
                        index={index}
                        handleChange={handleChange}
                        sx={{ minWidth: "130px" }}
                    />
                </Stack>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    alignItems={{ md: "stretch", lg: "center" }}
                >
                    <Stack direction="row">
                        <CheckboxField
                            values={values}
                            rangeName={rangeName}
                            name="Abnormal"
                            index={index}
                            handleChange={handleChange}
                        />
                        <CheckboxField
                            values={values}
                            rangeName={rangeName}
                            name="Critical"
                            index={index}
                            handleChange={handleChange}
                        />
                        <CheckboxField
                            values={values}
                            rangeName={rangeName}
                            name="CombineValueDisplay"
                            label="Dual Display"
                            index={index}
                            handleChange={handleChange}
                        />
                    </Stack>
                    <CustomTextField
                        values={values}
                        rangeName={rangeName}
                        name="DisplayValue"
                        label="Display Value"
                        index={index}
                        handleChange={handleChange}
                        sx={{ minWidth: "130px" }}
                    />
                    <CustomTextField
                        values={values}
                        rangeName={rangeName}
                        name="Comment"
                        label="Comment"
                        index={index}
                        handleChange={handleChange}
                        sx={{ minWidth: "130px" }}
                    />
                    <IconButton
                        color="error"
                        onClick={() =>
                            removeRange(setFieldValue, index, values, rangeName)
                        }
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Divider sx={{ background: "rgb(0, 142, 108)" }} />
        </>
    );
}

const AgeField = ({ values, rangeName, name, index, handleChange, label }) => (
    <TextField
        required
        label={label}
        size="small"
        name={`${rangeName}.${index}.${name}`}
        value={values?.[rangeName]?.[index]?.[name]}
        onChange={handleChange}
        inputProps={{ type: "number", min: 0, max: 150 }}
        sx={{ minWidth: "80px" }}
    />
);

const SexField = ({ values, rangeName, name, index, handleChange, ...props }) => (
    <TextField
        select
        required
        label="Sex"
        size="small"
        name={`${rangeName}.${index}.${name}`}
        value={values?.[rangeName]?.[index]?.Sex}
        onChange={handleChange}
        sx={{ minWidth: "80px" }}
        {...props}
    >
        <MenuItem key={0} value={"A"}>
            All
        </MenuItem>
        <MenuItem key={1} value={"M"}>
            Male
        </MenuItem>
        <MenuItem key={2} value={"F"}>
            Female
        </MenuItem>
    </TextField>
);
const CustomNumberField = ({
    values,
    rangeName,
    name,
    index,
    handleChange,
    label,
    errors,
    touched,
    ...props
}) => (
    <TextField
        name={`${rangeName}.${index}.${name}`}
        label={label || name}
        helperText={
            touched?.[rangeName]?.[index]?.[name] &&
            errors?.[rangeName]?.[index]?.[name]
        }
        error={
            touched?.[rangeName]?.[index]?.[name] &&
            Boolean(errors?.[rangeName]?.[index]?.[name])
        }
        size="small"
        onChange={handleChange}
        value={values?.[rangeName]?.[index]?.[name]}
        inputProps={{ type: "number", step: 0.01 }}
        {...props}
    />
);

const CustomTextField = ({
    values,
    rangeName,
    name,
    index,
    handleChange,
    label,
    errors,
    touched,
    ...props
}) => (
    <TextField
        name={`${rangeName}.${index}.${name}`}
        label={label || name}
        helperText={
            touched?.[rangeName]?.[index]?.[name] &&
            errors?.[rangeName]?.[index]?.[name]
        }
        error={
            touched?.[rangeName]?.[index]?.[name] &&
            Boolean(errors?.[rangeName]?.[index]?.[name])
        }
        size="small"
        onChange={handleChange}
        value={values?.[rangeName]?.[index]?.[name]}
        {...props}
    />
);

const CheckboxField = ({ values, label, rangeName, name, index, handleChange }) => (
    <FormControlLabel
        control={
            <Checkbox
                name={`${rangeName}.${index}.${name}`}
                checked={values?.[rangeName]?.[index]?.[name]}
                onChange={handleChange}
            />
        }
        labelPlacement="bottom"
        label={label || name}
    />
);
const addRange = (setFieldValue, values, rangeType) => {
    let initValues;
    let rangeName;
    switch (rangeType) {
        case "ListRange":
            initValues = ListRangeInit;
            rangeName = "RefRangeList";
            break;
        case "NumericRange":
            initValues = NumericRangeInit;
            rangeName = "RefRangeNumeric";
            break;
    }

    setFieldValue(rangeName, [...values?.[rangeName], initValues]);
};

const removeRange = (setFieldValue, index, values, rangeName) => {
    if (
        values?.[rangeName]?.length === 0
    ) {
        /// if paymentMethod is insurance - an insurance must be defined
        return;
    }
    const updatedRange = [...values?.[rangeName]];
    updatedRange.splice(index, 1);
    setFieldValue(rangeName, [...updatedRange]);
};

function ListRange({ values, index, setFieldValue, handleChange }) {
    const rangeName = "RefRangeList";
    return (
        <>
            <Stack
                direction={{ lg: "column", xl: "row" }}
                spacing={{ md: 2, lg: 0 }}
                alignItems={{ md: "stretch", lg: "center" }}
            >
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    alignItems={{ md: "stretch", lg: "center" }}
                >
                    <AgeField
                        values={values}
                        rangeName={rangeName}
                        name="AgeFrom"
                        index={index}
                        handleChange={handleChange}
                        label="Age From"
                    />
                    <AgeField
                        values={values}
                        rangeName={rangeName}
                        name="AgeTo"
                        index={index}
                        handleChange={handleChange}
                        label="Age To"
                    />
                    <SexField
                        values={values}
                        rangeName={rangeName}
                        name="Sex"
                        index={index}
                        handleChange={handleChange}
                    />
                    <CustomTextField
                        values={values}
                        rangeName={rangeName}
                        name="Value"
                        index={index}
                        handleChange={handleChange}
                    />
                </Stack>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={2}
                    alignItems={{ md: "stretch", lg: "center" }}
                >
                    <Stack direction="row">
                        <CheckboxField
                            values={values}
                            rangeName={rangeName}
                            name="Abnormal"
                            index={index}
                            handleChange={handleChange}
                        />
                        <CheckboxField
                            values={values}
                            rangeName={rangeName}
                            name="Critical"
                            index={index}
                            handleChange={handleChange}
                        />
                    </Stack>
                    <CustomTextField
                        values={values}
                        rangeName={rangeName}
                        name="DisplayValue"
                        label="Display Value"
                        index={index}
                        handleChange={handleChange}
                        sx={{ minWidth: "130px" }}
                    />
                    <CustomTextField
                        values={values}
                        rangeName={rangeName}
                        name="Comment"
                        label="Comment"
                        index={index}
                        handleChange={handleChange}
                        sx={{ minWidth: "130px" }}
                    />
                    <IconButton
                        color="error"
                        onClick={() =>
                            removeRange(setFieldValue, index, values, rangeName)
                        }
                    >
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Divider sx={{ background: "rgb(0, 142, 108)" }} />
        </>
    );
}

function ReferenceRange({
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
}) {
    return (
        <>
            <Stack direction="row" spacing={2}>
                <TextField
                    label="Min Reportable Value"
                    size="small"
                    name={"MinReportableValue"}
                    value={values?.MinReportableValue}
                    onChange={handleChange}
                    sx={{ maxWidth: { md: "100%", lg: "200px" } }}
                    inputProps={{
                        type: "number",
                        step: 0.01,
                    }}
                />
                <TextField
                    label="Max Reportable Value"
                    sx={{ maxWidth: { md: "100%", lg: "200px" } }}
                    size="small"
                    name={"MaxReportableValue"}
                    value={values?.MaxReportableValue}
                    onChange={handleChange}
                    inputProps={{
                        type: "number",
                        step: 0.01,
                    }}
                />
                <TextField
                    label="Precision"
                    sx={{ maxWidth: { md: "100%", lg: "200px" } }}
                    size="small"
                    name={"Precision"}
                    value={values?.Precision}
                    onChange={handleChange}
                    inputProps={{
                        type: "number",
                        step: 1,
                        min: 0,
                        max: 6
                    }}
                />
            </Stack>
            <Divider />
            {values.RefRangeNumeric.map((_, i) => (
                <NumericRange
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    index={i}
                    errors={errors}
                    touched={touched}
                />
            ))}
            {values.RefRangeList.map((_, i) => (
                <ListRange
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    index={i}
                />
            ))}
        </>
    );
}

function DependencyTable({ values, setFieldValue, visible }) {
    if (!visible) {
        return <></>;
    }

    const dependencies = values?.Dependencies.map((t) => t.ID);
    const setDependencies = (testIDs) => {
        setFieldValue("Dependencies", [
            ...testIDs.map((t) => {
                return { ID: t };
            }),
        ]);
    };

    return (
        <Box>
            <Divider sx={{ p: 1, my: 1 }} />
            <Typography variant="h5" sx={{ my: 1 }}>
                Test Dependencies
            </Typography>
            <Box height="300px">
                <TestsTable
                    getTests={getTests}
                    tests={dependencies}
                    setTests={setDependencies}
                    setTestDetails={() => { }}
                    checkboxes={true}
                />
            </Box>
        </Box>
    );
}

export default function MethodForm({ methodDetails, saved }) {
    const [allInstruments, setAllInstruments] = useState([]);
    const [tests, setTests] = useState([]);
    const [hasDependencies, setHasDependencies] = useState(
        false || methodDetails?.Dependencies.length > 0
    );
    const [error, setError] = useState(null);

    const validationSchema = Yup.object().shape({
        Test: Yup.object().required("Test is required"),
        Units: Yup.string(),
        Instruments: Yup.array(),
        InstrumentTestCode: Yup.string(),
        AllowReplicates: Yup.boolean(),
        ReplicateExpression: Yup.string(),
        CalculationExpression: Yup.string(),
        HasDependencies: Yup.boolean(),
        MaxReportableValue: Yup.number().nullable(),
        MinReportableValue: Yup.number().nullable(),
        RefRangeList: Yup.array(),
        RefRangeNumeric: Yup.array()
            .of(
                Yup.object().shape({
                    AgeFrom: Yup.number().required(
                        "Age From is required"
                    ),
                    AgeTo: Yup.number().required("Age To is required"),
                    ValueFrom: Yup.number().nullable(),
                    ValueTo: Yup.number().nullable(),
                })
            )
            .test(
                "either ValueFrom or ValueTo",
                "Either Value From or Value To is required",
                function(value) {
                    return (
                        value.ValueFrom !== null ||
                        value.ValueTo !== null
                    );
                }
            ),
    });

    useEffect(() => {
        getInstruments()
            .then((p) => {
                if (!p.ok) {
                    throw new Error("failed to load instruments.");
                }
                return p.json();
            })
            .then((p) => {
                setAllInstruments(p);
            })
            .catch((e) => setError(e.message));

        const getFilteredTests = async () => {
            try {
                const response = await getMethods();
                if (!response.ok) {
                    throw new Error("Failed to load methods.");
                }
                let tests = await getTests();

                tests = tests.filter(
                    (test) => !test.IsPanel
                );

                tests = tests.sort((a, b) => a?.Code > b?.Code)

                setTests(tests)
            } catch (e) {
                setError(e.message);
            }
        };

        if (!methodDetails?.ID) {
            getFilteredTests();
        }

    }, [methodDetails?.ID]);

    const handleSubmit = (values) => {
        if (values.ID) {
            updateMethod(values, values.ID)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to save method.");
                    }
                    return p.json();
                })
                .then(() => {
                    setError(null);
                    saved();
                })
                .catch((e) => setError(e.message));
        } else {
            addMethod(values)
                .then((p) => {
                    if (!p.ok) {
                        throw new Error("Failed to save method.");
                    }
                    return p.json();
                })
                .then(() => {
                    setError(null);
                    saved();
                })
                .catch((e) => setError(e.message));
        }
    };

    return (
        <Formik
            initialValues={methodDetails}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({ values, handleChange, errors, touched, setFieldValue }) => (
                <Form>
                    <Stack direction="column" spacing={2}>
                        <Stack direction={"row"} spacing={2} >
                            {methodDetails?.ID !== undefined ? (
                                <TextField
                                    value={methodDetails?.Test?.Code + " - " + methodDetails?.Test?.Name}
                                    label="Test"
                                    size="small"
                                    disabled
                                />
                            ) : (
                                <>
                                    <Autocomplete
                                        required
                                        options={tests}
                                        getOptionLabel={(row) => row.Code + " - " + row.Name}
                                        size="small"
                                        value={values?.Test || null}
                                        isOptionEqualToValue={(option, value) =>
                                            option.ID === value
                                        }
                                        onChange={(_, newValue) => { setFieldValue("Test", newValue); if (newValue) { setFieldValue("TestID", newValue.ID) } }}
                                        renderInput={(params) => {
                                            return (
                                                <TextField
                                                    required
                                                    sx={{ minWidth: "250px" }}
                                                    {...params}
                                                    label="Test"
                                                />
                                            );
                                        }}
                                    />
                                </>
                            )}

                            <TextField
                                size="small"
                                onChange={handleChange}
                                value={values?.Units}
                                name="Units"
                                label="Unit of measure"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="Has Dependencies"
                                        checked={hasDependencies}
                                        onChange={(event) =>
                                            setHasDependencies(
                                                event.target.checked
                                            )
                                        }
                                    />
                                }
                                label="Has Dependencies"
                                sx={{ ml: 1 }}
                            ></FormControlLabel>
                        </Stack>
                        <Divider sx={{ p: 1, my: 1 }} />
                        <InputLabel>Calculation</InputLabel>
                        <TextField
                            size="small"
                            onChange={handleChange}
                            value={values?.CalculationExpression}
                            name="CalculationExpression"
                            label="Calculation Expression"
                        />
                        <Typography variant="subtitle2">* If using the Test() function, ensure the test(s) are added to method dependencies</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="Allow Replicates"
                                    checked={values?.AllowReplicates}
                                    onChange={(event) =>
                                        setFieldValue("AllowReplicates",
                                            event.target.checked
                                        )
                                    }
                                />
                            }
                            label="Allow Replicates"
                            sx={{ ml: 1 }}
                        ></FormControlLabel>
                        {values?.AllowReplicates &&
                            <>
                                <InputLabel>Replicate Expression</InputLabel>
                                <TextField
                                    size="small"
                                    onChange={handleChange}
                                    value={values?.ReplicateExpression}
                                    name="ReplicateExpression"
                                    label="Replicate Expression"
                                /></>}
                        <Divider sx={{ p: 1, my: 1 }} />
                        <InputLabel>Instrument Configuration</InputLabel>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="flex-end"
                        >
                            <Autocomplete
                                multiple
                                variant="outlined"
                                id="tags-standard"
                                sx={{ minWidth: "200px" }}
                                options={allInstruments}
                                onChange={(_, value) => {
                                    setFieldValue("Instruments", value);
                                }}
                                value={values.Instruments}
                                getOptionLabel={(option) => option.Name}
                                isOptionEqualToValue={(option, value) =>
                                    option.ID === value.ID
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        label="Instruments"
                                        placeholder="Instruments"
                                    />
                                )}
                            />
                            <TextField
                                onChange={handleChange}
                                size="small"
                                required={values?.Instruments.length > 0}
                                value={values.InstrumentTestCode}
                                name="InstrumentTestCode"
                                label="Instrument Test Code"
                            />
                        </Stack>
                        <Divider sx={{ p: 1, my: 1 }} />
                        <Stack direction="column" spacing={2}>
                            <InputLabel>Reference Ranges</InputLabel>
                            <Stack direction="column" spacing={2} sx={{ m: 2 }}>
                                <ReferenceRange
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                />
                                <Stack direction={{ xs: "column", sm: "row" }} spacing={2} width="100%">
                                    <Button
                                        onClick={() =>
                                            addRange(setFieldValue, values, "NumericRange")
                                        }
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        sx={{
                                            color: "white",
                                        }}
                                    >
                                        Add Numeric Range
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            addRange(setFieldValue, values, "ListRange")
                                        }
                                        variant="contained"
                                        color="secondary"
                                        fullWidth
                                        sx={{
                                            color: "white",
                                        }}
                                    >
                                        Add List Range
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        <DependencyTable
                            values={values}
                            setFieldValue={setFieldValue}
                            visible={hasDependencies}
                        />
                        {error !== null ? (
                            <Alert severity="error">{error}</Alert>
                        ) : null}
                        <Stack direction="row" justifyContent="space-between">
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={saved}
                            >
                                Back
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                type="submit"
                            >
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}
